import { Injectable } from '@angular/core';
import Pusher from 'pusher-js';
import { AuthService } from './auth.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PusherService {
  private pusher: Pusher | null = null;

  constructor(private readonly authService: AuthService) {}

  getPusher(): Pusher {
    if (!this.pusher) {
      this.pusher = new Pusher(environment.pusher.key, {
        cluster: environment.pusher.cluster,
        authEndpoint: environment.pusher.authEndpoint,
        forceTLS: true,
        auth: {
          headers: {
            Authorization: `Bearer ${this.authService.getToken()}`,
          },
        },
      });
    }
    if (this.pusher) {
      return this.pusher;
    } else {
      throw new Error('Pusher is not initialized');
    }
  }
}
